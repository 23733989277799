
import type { ShowroomAppProps } from './_app'
import type { Content } from 'types/Content.interface'
import type { NextPage } from 'next'
import Layout from 'layouts/Layout'

interface PageProps extends ShowroomAppProps, Content {}

const PageNotFound: NextPage<PageProps> = ({ isAuthenticated }) => {
  return <Layout title="404 - Sidan finns inte" meta={[]} isAuthenticated={isAuthenticated}>
    <div className="container flex flex-col justify-center text-center">
      <h1 className="heading-h1 mt-20 mb-10">404</h1>
      <h3 className="heading-h3 mb-20">Sidan finns inte</h3>
    </div>
  </Layout>
}

export default PageNotFound
